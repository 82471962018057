/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import 'core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import 'core/scss/base/components/variables'; // Bootstrap includes

@import 'core/scss/base/plugins/forms/form-wizard.scss';
@import 'core/scss/react/libs/flatpickr/flatpickr.scss';

@import './inputs.scss';
@import './badges.scss';
// @import '@styles/react/libs/flatpickr/flatpickr.scss';

.boolean-icon-true {
  color: $primary;
}

.boolean-icon-false {
  color: $red;
}

.app-content {
  padding-top: 2rem !important;
}

.amount-positive {
  color: var(--bs-success);
}
.amount-zero {
  color: var(--bs-warning);
}
.amount-negative {
  color: var(--bs-danger);
}

.scrollbar-container {
  overflow: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
}

.badge.bg-suspended {
  background-color: #E5BE10;
  color: white;
}

.badge.bg-light-suspended {
  background-color: #F8E0A8;
  color: darken(#E5BE10, 15%);
}

.dropdown-menu {
  a {
    color: $secondary;

    &:hover {
      color: $secondary;
    }
  }

  li {
    &:active {
      a {
        color: white;
      }
    }
  }
}

.content-header-title {
  border-right: none !important;

  &.with-border {
    border-right: 1px solid lighten($gray-600, 10%) !important;
  }
}

.prev-item .page-link, .next-item .page-link {
  border-radius: 25px !important;
}

.input-group {
  width: auto;
}

.bs-stepper-header {
  text-align: unset !important; // fix when steps are centered in center in small devices
}

.flatpickr-clear-icon {
  &:hover {
    cursor: pointer;
  }
}

.form-switch-md {
  height: 1.75rem !important;
  width: 3.5rem !important;
}

.content-header .breadcrumb {
  padding-left: 0;
}

.nav.nav-tabs {
  .nav-link {
    padding: inherit;
    padding-bottom: 0.61rem;
    padding-left: 0rem;
    margin-right: 1rem;
  }
}

.content, .header-navbar, .navbar-container {
  transition: none !important;
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

@include media-breakpoint-down(md) {
  html body .app-content {
    padding: 2rem calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ebe9f1;
}

.hover-gray {
  &:hover {
    background-color: var(--bs-gray-200);
  }
}

tr.tr-dark {
  background-color: darken(white, 5);

  &:hover {
    background-color: white;
  }
}

@include media-breakpoint-up(md) {
  .float-right-md {
    float: right;
  }
}

.secondary-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit !important;
    cursor: pointer;
    text-decoration: underline;
  }
}

.bg-white {
  background-color: white;
}

.accordion-header-color {
    background-color: #f3f2f7;

  .accordion-button.collapsed {
    background-color: darken(#f3f2f7, 1%);
  }
}

.td-no-padding {
  padding: 0 !important;
  padding-left: 3px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-align: center;
}

.table-hover {
  &:hover {
    cursor: inherit !important;
  }

  td:hover, tr:hover {
    cursor: inherit !important;
  }
}

.table-small-heading {
  th {
    white-space: nowrap !important;
    font-size: 0.75rem !important;
  }
}
