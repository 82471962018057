.bg-badge-pickup {
  // background-color: #f4d1ae;
  background-color: #b6b8ce;
  width: 40px;
}

.bg-badge-delivery{
  // background-color: #c5d8d1;
  background-color: #6c719d;
  width: 40px;
}
