@import 'core/scss/base/bootstrap-extended/_variables';

.flatpickr-day.inRange {
  color: #6e6b7b
}
.flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange {
  color: lighten(#6e6b7b, 5);
}

.form-control:disabled, .form-control[readonly]:not(.flatpickr-input),
.flatpickr-input.flatpickr-readonly {
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  opacity: inherit;
}

.rbt.readonly {
  .rbt-input, .rbt-input-wrapper {
    border-color: #f7f7f7 !important;
    background-color: #f7f7f7 !important;
    border: 0 !important;
    border-width: 0 !important;
    border-color: transparent !important;
  }
}

// .flatpickr-clear-icon.input-group-text.is-invalid {
  // background-color: #f7f7f7;
// }

input.readonly:not(.flatpickr-input), .flatpickr-readonly, {
  border-color: #f7f7f7;
  background-color: #f7f7f7;

  &:focus {
    border: none;
  }
}

.input-group-text.readonly, .input-group.readonly {
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  border: 0 !important;
  border-width: 0 !important;
  border-color: transparent !important;
}

input.readonly, .input-group-text.readonly, .input-group.readonly {
  border: 0 !important;
  border-width: 0 !important;
  border-color: transparent !important;
}

input.flatpickr-mobile {
  // Left padding and border is cut off on mobile
  padding: $input-padding-y $input-padding-x !important;
  border: 1px solid $input-border-color !important;
  border-top-left-radius: $input-border-radius !important;
  border-bottom-left-radius: $input-border-radius !important;

  &:focus {
    border: 1px solid $input-focus-border-color !important;
  }
}
